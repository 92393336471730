<template>
  <el-card id="managedAccount"
    v-loading="loading"
    shadow="never"
    class="content-card">
    <h3 v-if="currentAccount">
      {{ $t('partner.managed-account') }} - {{ currentAccount.accountName }}
    </h3>
    <h3 v-else>
      {{ $t('partner.new-managed') }}
    </h3>
    <hr class="my-2">

    <template v-if="!newCompany && !currentAccount">
      <p>{{ $t('partner.invalid-company-uid') }}</p>
    </template>

    <template v-else>
      <generic-form :fields="formFields"
        :form-data="formData"
        :submit-text="submitText"
        :disabled="!hasAuth('company_admin')"
        :show-cancel="true"
        label-width="300px"
        @cancel="handleCancel"
        @update="handleUpdateForm"
        @submitted="handleSubmit" />

      <div v-if="currentAccount && hasAuth('company_admin')"
        id="manageActions">
        <hr class="mt-2 mb-3">

        <h5>{{ $t('partner.edit-users') }}</h5>
        <p class="mt-0">
          {{ $t('partner.edit-users-text') }} <a data-name="partner-manage-users"
            @click.prevent="goTo('/company/users')">{{ $t('menu.manage-users') }}</a>
        </p>

        <h5 class="mt-1">
          {{ $t('partner.view-services') }}
        </h5>
        <p class="mt-0">
          {{ $t('partner.view-services-text') }} <a data-name="partner-manage-services"
            @click.prevent="goTo(resolveServicesPage())">{{ $t('partner.manage-services') }}</a>
        </p>
      </div>
    </template>
  </el-card>
</template>

<script>
import GenericForm from '@/components/ui-components/GenericForm.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { PHONE_NUMBER_REGEX } from '@/validators'

export default {
  name: 'ManagedAccount',

  components: {
    'generic-form': GenericForm,
  },

  props: {
    companyUid: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        accountName: '',
        accountRef: '',
        techSupportPhone: '',
        techSupportEmail: '',
      },
      checkingValidation: false,
      currentAccount: null,
      loading: true,
    }
  },

  computed: {
    ...mapState('Company', ['data']),
    ...mapState('ManagedCompanies', ['managedCompaniesList']),
    ...mapGetters('Auth', ['hasAuth', 'isMegaportSupported']),
    ...mapGetters('ManagedCompanies', ['getManagedAccountFromId']),
    submitText() {
      return this.currentAccount ? this.$t('partner.save') : this.$t('partner.create')
    },
    newCompany() {
      return this.companyUid === 'new'
    },
    formFields() {
      return [
        {
          key: 'accountName',
          required: true,
          placeholder: this.$t('partner.account-name'),
          type: 'text',
          demo: 'A managed account',
          label: this.$t('partner.account-name'),
          rules: { required: true, whitespace: true, message: this.$t('validations.required', { thing: this.$t('partner.account-name') }), trigger: 'blur' },
        },
        {
          key: 'accountRef',
          required: true,
          placeholder: this.$t('partner.managed-account-ref-placeholder'),
          type: 'text',
          demo: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
          label: this.$t('partner.managed-account-ref'),
          rules: [
            { required: true, whitespace: true, message: this.$t('validations.required', { thing: this.$t('partner.managed-account-ref') }), trigger: 'blur' },
            { min: 0, max: 128, message: this.$tc('validations.max-length', 128, { max: 128 }) },
          ],
        },
        {
          key: 'techSupportPhone',
          required: true,
          placeholder: this.$t('tscd.tech-supp-phone-placeholder'),
          type: 'text',
          demo: '+61 4321 1234',
          label: this.$t('tscd.tech-supp-phone-num'),
          rules: [
            { required: this.isMegaportSupported, whitespace: true, message: this.$t('validations.required', { thing: this.$t('tscd.tech-supp-phone-num') }), trigger: 'blur' },
            { pattern: PHONE_NUMBER_REGEX, message: this.$t('validations.phone-format'), trigger: 'blur' },
          ],
        },
        {
          key: 'techSupportEmail',
          required: true,
          placeholder: this.$t('tscd.tech-supp-email-placeholder'),
          type: 'text',
          demo: 'controller1.versa.com',
          label: this.$t('tscd.tech-supp-email'),
          rules: [
            { required: this.isMegaportSupported, whitespace: true, message: this.$t('validations.required', { thing: this.$t('tscd.tech-supp-email') }), trigger: 'blur' },
            { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
          ],
        },
      ]
    },
  },
  created() {
    if (this.newCompany) {
      this.loading = false
      return
    }

    this.fetchManagedAccount()
  },
  methods: {
    resolveServicesPage,
    ...mapActions('ManagedCompanies', ['getManagedCompanies', 'updateManagedCompanies', 'createNewManagedCompanies']),
    goBack() {
      this.$router.push('/partner', () => { })
    },
    async fetchManagedAccount() {
      try {
        await this.getManagedCompanies()
        this.currentAccount = this.getManagedAccountFromId(this.companyUid)

        if (!this.currentAccount) {
          const props = {
            title: this.$t('partner.error'),
            message: this.$t('partner.invalid-company-uid'),
            duration: 3000,
          }
          this.$notify.error(props)
        }
      } catch (err) {
        if (err.status === '404') {
          const props = {
            title: this.$t('partner.error'),
            message: this.$t('partner.no-match'),
            duration: 3000,
          }
          this.$notify.error(props)
        }
      } finally {
        this.setFormFields()
        this.loading = false
      }
    },
    setFormFields() {
      if (this.currentAccount) {
        const { accountName = '', accountRef = '', techSupportPhone = '', techSupportEmail = '' } = this.currentAccount
        this.formData.accountName = accountName
        this.formData.accountRef = accountRef
        this.formData.techSupportPhone = techSupportPhone
        this.formData.techSupportEmail = techSupportEmail
      } else {
        this.formData.accountName = ''
        this.formData.accountRef = ''
        this.formData.techSupportPhone = ''
        this.formData.techSupportEmail = ''
      }
    },
    handleUpdateForm(data) {
      const { key, value } = data
      this.formData = {
        ...this.formData,
        [key]: value,
      }
    },
    async handleSubmit() {
      this.loading = true
      const postData = {
        accountRef: this.formData.accountRef.trim(),
        accountName: this.formData.accountName.trim(),
        techSupportPhone: this.formData.techSupportPhone.trim(),
        techSupportEmail: this.formData.techSupportEmail.trim(),
      }

      try {
        if (this.currentAccount) {
          postData.companyUid = this.currentAccount.companyUid
          const { data } = await this.updateManagedCompanies(postData)
          this.setFormFields(data)
        } else {
          await this.createNewManagedCompanies(postData)
          this.$router.push('/partner')
        }

        const props = {
          title: this.$t('general.success'),
          message: this.$t(this.currentAccount ? 'partner.account-saved' : 'partner.account-created'),
          duration: 4000,
        }
        this.$notify.success(props)
        this.currentAccount && await this.fetchManagedAccount()

      } catch (err) {
        const props = {
          title: this.$t(this.currentAccount ? 'partner.update-account-error' : 'partner.create-account-error'),
          message: err.message,
          duration: 3000,
        }
        this.$notify.error(props)
      } finally {
        this.loading = false
      }
    },
    handleCancel() {
      this.goBack()
    },
    isCompanyManaged(companyUid) {
      return !!this.managedCompaniesList.find(company => company.companyUid === companyUid)
    },
    getAccountType(isManaged) {
      return isManaged ? this.$tc('partner-admin.managed-account', 1) : this.$t('partner-admin.partner-account')
    },
    goTo(route) {
      const { companyUid, currentAccount: { accountName } } = this
      const { name: fromCompany, companyUid: fromCompanyUid } = this.data

      if (fromCompanyUid === companyUid) {
        this.$router.push(route)
      } else {
        const props = {
          accountTypeFrom: this.getAccountType(this.isCompanyManaged(fromCompanyUid)),
          fromCompany,
          accountTypeTo: this.getAccountType(this.isCompanyManaged(companyUid)),
          toCompany: accountName,
        }

        this.$confirm(this.$t('general.context-switching-message', props), this.$t('general.context-switching-title'), {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t('general.confirm'),
          cancelButtonText: this.$t('general.cancel'),
          showClose: false,
          closeOnClickModal: false,
        }).then(() => {
          this.$router.push({
            path: route,
            query: {
              managedCompanyUid: companyUid,
              companyName: accountName,
            },
          }).catch(() => { })
        }).catch(() => { })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
h3.manage-header {
  margin-top: 4rem;
}
#manageActions a {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: 600;
}
</style>
