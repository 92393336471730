<template>
  <section :id="uniqueId('form-')">
    <el-form ref="genericForm"
      :model="formData"
      :label-width="labelWidth"
      :rules="rules"
      :disabled="disabled"
      @keyup.enter.native="onSubmit">
      <template v-for="(formatter, idx) in fields">
        <!-- Text Input -->
        <el-form-item v-if="formatter.type === G_FIELD_TYPE_TEXT"
          :key="`${formatter.key}-${idx}`"
          :prop="formatter.key"
          :label="formatter.label"
          :data-name="kebabCase(formatter.key)">
          <el-input :value="formData[formatter.key]"
            :data-demo="formatter.demo || ''"
            :placeholder="formatter.placeholder || ''"
            @input="handleInputChange($event, formatter.key)" />
        </el-form-item>
      </template>

      <!-- Actions -->
      <el-form-item class="form-actions">
        <el-button v-if="showCancel"
          id="cancelButton"
          @click="onCancel">
          {{ $t('general.cancel') }}
        </el-button>
        <el-button v-if="showRevert"
          id="revertButton"
          :disabled="!dirty"
          @click="onRevert">
          {{ $t('general.revert') }}
        </el-button>
        <el-button id="submitButton"
          type="primary"
          :disabled="!dirty"
          @click="onSubmit">
          {{ submitText }}
        </el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import { kebabCase, uniqueId } from 'lodash'

export default {
  name: 'GenericForm',
  props: {
    fields: {
      type: Array,
      required: true,
    },
    formData: {
      type: Object,
      default: () => { },
    },
    labelWidth: {
      type: String,
      default: '180px',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
    showRevert: {
      type: Boolean,
      default: false,
    },
    submitText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isValidForm: false,
      dirty: false,
    }
  },
  computed: {
    rules() {
      return this.fields.reduce((acc, cur) => {
        if (cur.rules) {
          return {
            ...acc,
            [cur.key]: cur.rules,
          }
        }
      }, {})
    },
  },
  methods: {
    kebabCase,
    uniqueId,
    checkValidation() {
      this.$refs.genericForm.validate(valid => {
        this.isValidForm = valid
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            duration: 3000,
          }
          this.$notify.error(props)
        } else {
          this.$emit('isValid', this.isValidForm)
        }
      })
    },
    handleInputChange(value, key) {
      this.$emit('update', {
        key,
        value,
      })

      if (!this.dirty) {
        this.dirty = true
      }
    },
    onSubmit() {
      this.checkValidation()

      if (this.isValidForm) {
        this.$emit('submitted')
      }
    },
    onRevert() {
      this.$refs.genericForm.resetFields()
      this.$emit('revert')
      this.dirty = false
    },
    onCancel() {
      this.$refs.genericForm.resetFields()
      this.$emit('cancel')
      this.dirty = false
    },
  },
}
</script>

<style lang="scss" scoped>
.form-actions {
  display: flex;
  justify-content: flex-start;
}
</style>
