<template>
  <section>
    <el-container class="flex-wrap">
      <div class="process-steps-header full-width sticky-top">
        <div class="p-20px flex-row-centered">
          <i class="fas fa-2x mr-1 color-primary fa-arrow-circle-left"
            aria-hidden="true"
            @click="goBack()" />
          <h3>{{ $t('menu.partner-admin') }}</h3>
        </div>
      </div>
      <el-main id="managedAdminContent">
        <el-row :gutter="24">
          <el-col :xs="24"
            :lg="6"
            class="mb-2">
            <managed-documentation />
          </el-col>
          <el-col :xs="24"
            :lg="18">
            <managed-account :company-uid="companyUid" />
          </el-col>
        </el-row>
      </el-main>
    </el-container>
  </section>
</template>

<script>
import ManagedDocumentation from '@/components/partner/ManagedDocumentation.vue'
import ManagedAccount from '@/components/partner/ManagedAccount.vue'

export default {
  name: 'ManagedAdmin',

  components: {
    'managed-documentation': ManagedDocumentation,
    'managed-account': ManagedAccount,
  },

  data() {
    return {
      companyUid: null,
    }
  },

  created() {
    this.companyUid = this.$route.params.companyUid
  },

  methods: {
    goBack() {
      this.$router.push('/partner', () => { })
    },
  },
}
</script>

<style lang="scss" scoped>
#managedAdminContent {
  padding-bottom: 50px;
  margin: auto;
}

.fa-arrow-circle-left {
  cursor: pointer;

  &:hover {
    color: #4eb1f7;
  }
}

div.process-steps-header {
  padding-top: unset;
}
</style>
